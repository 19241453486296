import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import styled from '@material-ui/core/styles/styled'; // Add this import

import * as actions from '../redux/actions';

import {
  CustomButton, Typography, TextField, AutoComplete
} from '../../components';
import {
  Portlet, PortletBody, PortletHeader,
} from '../../../../_metronic/partials/content/Portlet';

const useStyles = makeStyles((theme) => ({
  container: {
    transform: 'rotateX(180deg)',
    overflowX: 'auto',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    overflowX: 'visible',
    transform: 'rotateX(180deg)',
  },
  item: {
    minWidth: '45%',
    maxWidth: '45%',
    [theme.breakpoints.down(1380)]: {
      minWidth: '70%',
      maxWidth: '70%',
    },
    [theme.breakpoints.down(500)]: {
      minWidth: '85%',
      maxWidth: '85%',
    },
  },
  clearButton: {
    alignItems: 'start',
    height: 50,
    width: 162,
  },
  tableRow: {
    borderBottom: '1px solid lightgrey',
    borderRight: '1px solid lightgrey',
    borderLeft: '1px solid lightgrey',
    marginLeft: 0,
    marginRight: 0,
  },
  body: {
    margin: '25px 0px 0px 0px',
    borderRadius: '0 !important',
    boxShadow: 'none !important',

  },
  tableLabel: {
    borderBottom: 'none !important',
    borderRadius: '0 !important',
    padding: '0 !important',
    marginBottom: 15,
  },
  tableBody: {
    padding: '0 !important'
  },
  title: {
    color: '#6C7293',
    textTransform: 'uppercase'

  },
  topButtonContainer: {
    display: 'flex',
    gap: 15,
  },
  searchBox: {
    flex: 1,
    width: 400,
    [theme.breakpoints.down('800')]: {
      // width: '100%',
      marginBottom: '20px'
    },
  },
  downloadButton: {
    width: 280,
    justifyContent: 'flex-end',

  },
  tableHeader: {
    backgroundColor: '#3398fc',
    marginRight: 0,
    marginLeft: 0,
    marginTop: '0 !important',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    padding: '8px 0',
    minHeight: 72,
  },
  tableHeaderFont: {
    color: '#FFF'
  },
  imageContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginBottom: '1rem',
    padding: '0px 10px',
  },
  closeButton: {
    position: 'absolute',
    top: '5px',
    right: '15px',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    padding: '5px',
  },
  synonymButton: {
    margin: '5px',
  },
  addButton: {
    margin: '5px',
    cursor: 'pointer',
    color: '#0086C2',
    fontWeight: 'bold',
  },
  input: {
    border: 'none',
    background: 'transparent',
    color: '#2A95FF', // Update text color
    outline: 'none',
    width: '100%',
  },
}));

const Synonyms = ({
  intl,
  matSynonyms,
  onSynonymsChange
}) => {
  const classes = useStyles();
  const [synonyms, setSynonyms] = useState(matSynonyms || []);
  const [newSynonym, setNewSynonym] = useState('');

  const handleAddSynonym = () => {
    const updatedSynonyms = [...synonyms, ''];
    setSynonyms(updatedSynonyms);
    onSynonymsChange(updatedSynonyms);
    setNewSynonym('');
  };

  const handleSynonymChange = (id, value) => {
    const updatedSynonyms = synonyms.map((synonym, index) => index === id ? value : synonym);
    setSynonyms(updatedSynonyms);
    onSynonymsChange(updatedSynonyms);
  };

  const handleBlur = (id, value) => {
    handleSynonymChange(id, value);
    setNewSynonym('');
  };

  const handleRemoveSynonym = (id) => {
    const updatedSynonyms = synonyms.filter((_, index) => index !== id);
    setSynonyms(updatedSynonyms);
    onSynonymsChange(updatedSynonyms);
  };

  return (
    <div style={{ width: '100%' }}>
      <Portlet className={`w-100 position-relative ${classes.body}`}>
        <PortletHeader
          className={`${classes.tableLabel} w-100`}
          title={(
            <div className="d-flex align-items-center w-100 justify-content-between">
              <Typography variant="h1" className={`${classes.title}`}>
                Synonyms
              </Typography>
            </div>
          )}
        />
      </Portlet>
      <div style={{ width: '100%', padding: '20px', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
        {synonyms.map((synonym, index) => (
          <StyledTabButton key={index} className={classes.synonymButton} style={{ background: synonym === '' ? '#fff' : '#2A95FF', color: synonym === '' ? '#2A95FF' : '#fff' }}>
            {synonym === '' ? (
              <input
                className={classes.input}
                value={newSynonym}
                onChange={(e) => setNewSynonym(e.target.value)}
                onBlur={(e) => handleBlur(index, e.target.value)}
                placeholder="Enter synonym"
              />
            ) : (
              <>
                {synonym}
                <span style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handleRemoveSynonym(index)}>X</span>
              </>
            )}
          </StyledTabButton>
        ))}
        <div className={classes.addButton} onClick={handleAddSynonym}>
          + Add a Synonym
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({

});

export default injectIntl(connect(mapStateToProps, actions)(Synonyms));

const StyledTabButton = styled(({ color, ...props }) => <div {...props} />)({
  color: '#fff',
  background: '#2A95FF',
  borderRadius: 16,
  padding: '8px 18px',
  letterSpacing: '0.335px',
  transition: 'all .3s',
  fontSize: 12,
  border: '1px solid #2A95FF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  textShadow: '-0.08ex 0 currentColor, 0.08ex 0 currentColor',
});
