import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../redux/createReducer';
import initialState from './store';

const materialsReducer = persistReducer(
  { storage, key: 'materials', whitelist: [] },
  createReducer(initialState, {
    SET_CATEGORY_LOADING_ACTION: (state, { payload }) => Immutable({
      ...state,
      fetchState: payload,
      responseMessage: initialState.responseMessage,
    }),

    SET_CATEGORY_SNACKBAR_ACTION: (state, { payload }) => Immutable({
      ...state,
      snackbarState: payload,
      responseMessage: initialState.responseMessage,
    }),

    SET_LOCALITY_ACTION: (state, { payload }) => Immutable({
      ...state,
      locality: payload.locality,
    }),

    GET_ALL_MATERIALS_REQUEST: (state) => Immutable({
      ...state,
      fetchState: 'send-request',
    }),
    GET_ALL_MATERIALS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      materials: payload.data.data || [],
      fetchState: 'succeed-request',
    }),
    GET_ALL_MATERIALS_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),
    GET_ONE_MATERIAL_DETAILS_REQUEST: (state) => Immutable({
      ...state,
      fetchState: 'send-request',
    }),
    GET_ONE_MATERIAL_DETAILS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      materialDetails: payload.data.data || null,
      fetchState: 'succeed-request',
    }),
    GET_ONE_MATERIAL_DETAILS_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),
    SAVE_ONE_MATERIAL_DETAILS_REQUEST: (state) => Immutable({
      ...state,
      fetchState: 'send-request-save-material',
    }),
    SAVE_ONE_MATERIAL_DETAILS_RECEIVE: (state) => Immutable({
      ...state,
      snackbarState: 'succeed-request-save-material',
      fetchState: 'succeed-request-save-material',
      responseStatus: 'success',
      responseMessage: 'Material has been updated',
    }),
    SAVE_ONE_MATERIAL_DETAILS_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),
    DELETE_ONE_MATERIAL_REQUEST: (state) => Immutable({
      ...state,
    }),
    DELETE_ONE_MATERIAL_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      materials: state.materials.filter((item) => item.mat_id !== payload.data),
      snackbarState: 'succeed-request',
      responseStatus: 'success',
      responseMessage: 'Material has been removed',
    }),
    DELETE_ONE_MATERIAL_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),
    UPLOAD_MATERIAL_IMAGE_REQUEST: (state) => Immutable({
      ...state,
    }),
    UPLOAD_MATERIAL_IMAGE_RECEIVE: (state) => Immutable({
      ...state,
      // snackbarState: 'succeed-request',
      responseStatus: 'success',
      // responseMessage: 'Image has been uploaded',
    }),
    UPLOAD_MATERIAL_IMAGE_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),
    CLEAR_MATERIAL_DETAILS: (state) => Immutable({
      ...state,
      materialDetails: null,
    }),
    ADD_MATERIAL_REQUEST: (state) => Immutable({
      ...state,
      fetchState: 'send-request-save-material',
    }),
    ADD_MATERIAL_RECEIVE: (state) => Immutable({
      ...state,
      snackbarState: 'succeed-request-save-material',
      fetchState: 'succeed-request-save-material',
      responseStatus: 'success',
      responseMessage: 'Material has been added',
    }),
    ADD_MATERIAL_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),
    CHECK_CIRCULAR_MATERIAL_LOCALITY_REQUEST: (state) => Immutable({
      ...state,
    }),
    CHECK_CIRCULAR_MATERIAL_LOCALITY_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      isCMLocality: payload.data.data.is_circular_material_locality,
    }),
    CHECK_CIRCULAR_MATERIAL_LOCALITY_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),
    SET_MATERIAL_SNACKBAR_MESSAGE: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.message.message,
      responseStatus: payload.message.status,
    }),
  })
);

export default materialsReducer;
