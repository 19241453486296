/**
 *
 */
const initialState = {
  fetchState: 'no-request',
  snackbarState: 'no-request',
  responseStatus: 'success',
  responseMessage: null,
  wasteTypeList: [],
  wasteType: {},
  categoryList: [],
  allCategoryList: [],
  depotList: [],
  eventList: [],
  collectionList: [],
  collectionDetails: null,
  search: [],
  materials: [],
  materialDetail: {},
  isCMLocality: false,
};

export default initialState;
