import {
  put, call, takeLatest, select
} from 'redux-saga/effects';

import actionTypes from './actionTypes';
import * as actions from './actions';
import {
  getMunicipality,
  getMunicipalityDetails,
  setMunicipalityHouseholds,
  getLocalities,
  getCounty,
  getDistrictProPackages,
  checkCircularMaterialLocality
} from './apis';

function* getMunicipalitySaga() {
  yield put(actions.setLoadingAction('send-request'));
  const res = yield call(getMunicipality, {});
  if (res.data) {
    yield put(actions.getMunicipalityReceive(res.data));

    const { municipalities } = yield select((store) => store.common);
    const { advocate_user } = yield select((store) => store.auth);
    if (municipalities.length > 1) {
      yield put(actions.setDistrictAction({
        district_id: '#all-districts#',
        district_nm: advocate_user === 't' ? 'All Workplaces' : 'All Districts'
      }));
    } else {
      yield put(actions.setDistrictAction(municipalities[0]));
    }
  } else {
    yield put(actions.getMunicipalityFail(res.error.detail));
  }
}

function* getCountySaga() {
  const county = yield call(getCounty);
  if (county.data) {
    yield put(actions.getCountyReceive(county.data));

    const { counties } = yield select((store) => store.common);
    // if (counties.length > 1) {
    //   yield put(actions.setCountyAction({
    //     district_id: '#all-districts#',
    //     district_nm: 'All Counties'
    //   }));
    // } else {
    // }
    yield put(actions.setCountyAction(counties[0]));
  } else {
    yield put(actions.getCountyFail(county.error.detail));
  }
}

function* getMunicipalityHouseholdsSaga({ payload: { district_list } }) {
  const res = yield call(getMunicipalityDetails, { district_list });
  if (res.data) {
    yield put(actions.getMunicipalityHouseholdsReceive(res.data));
  } else {
    yield put(actions.getMunicipalityHouseholdsFail(res.error.detail));
  }
}

function* setMunicipalityHouseholdsSaga({ payload: { district_id, household } }) {
  const res = yield call(setMunicipalityHouseholds, { district_id, household });
  if (res.data) {
    yield put(actions.setMunicipalityHouseholdsReceive({ household: Number(household) }));
  } else {
    yield put(actions.setMunicipalityHouseholdsFail(res.error.detail));
  }
}

function* getLocalitiesSaga() {
  try {
    yield put(actions.setLoadingAction('send-request'));
    const res = yield call(getLocalities);
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getLocalitiesReceive(res.data));
        yield put(actions.checkCircularMaterialLocalityRequest({ locality: res.data.data[0].cms_locality_id }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getLocalitiesFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* getDistrictProPackagesSaga() {
  try {

    yield put(actions.setLoadingAction('send-request'));
    const res = yield call(getDistrictProPackages);
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getDistrictProPackagesReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getDistrictProPackagesFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* checkCircularMaterialLocalitySaga({ payload: { locality } }) {
  try {
    const res = yield call(checkCircularMaterialLocality, { locality });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.checkCircularMaterialLocalityReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.checkCircularMaterialLocalityFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

/**
 * Saga watcher function
 */
function* sharedSagas() {
  yield takeLatest(actionTypes.GET_MUNICIPALITY_LIST_REQUEST, getMunicipalitySaga);
  yield takeLatest(actionTypes.GET_LOCALITIES_REQUEST, getLocalitiesSaga);
  yield takeLatest(actionTypes.GET_COUNTY_LIST_REQUEST, getCountySaga);
  yield takeLatest(actionTypes.GET_MUNICIPALITY_HOUSEHOLDS_REQUEST, getMunicipalityHouseholdsSaga);
  yield takeLatest(actionTypes.SET_MUNICIPALITY_HOUSEHOLDS_REQUEST, setMunicipalityHouseholdsSaga);
  yield takeLatest(actionTypes.GET_DISTRICT_PRO_PACKAGES_REQUEST, getDistrictProPackagesSaga);
  yield takeLatest(actionTypes.CHECK_CIRCULAR_MATERIAL_LOCALITY_REQUEST, checkCircularMaterialLocalitySaga);
}

export default sharedSagas;
