import { put, call, takeLatest } from 'redux-saga/effects';

import actionTypes from './actionTypes';
import * as actions from './actions';
import {
  getAllMaterials,
  getOneMaterialDetails,
  saveOneMaterialDetails,
  deleteOneMaterial,
  uploadMaterialImage,
  addMaterial,
  checkCircularMaterialLocality
} from '../apis';


function* getAllMaterialsSaga({ payload: { locality } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(getAllMaterials, { locality });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getAllMaterialsReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getAllMaterialsFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* getMaterialDetailsSaga({ payload: { locality, mat_id } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(getOneMaterialDetails, { locality, mat_id });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getOneMaterialDetailsReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getOneMaterialDetailsFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* saveMaterialDetailsSaga({ payload: { materialDetails } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(saveOneMaterialDetails, { material: materialDetails });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.saveOneMaterialsReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.saveOneMaterialsFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* deleteMaterialSaga({ payload: { locality, mat_id } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(deleteOneMaterial, { locality, mat_id });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.deleteOneMaterialReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.deleteOneMaterialFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

 function* uploadMaterialImageSaga({ payload: { locality, mat_id, image } }) {
    try {
      yield put(actions.setSnackbarAction('send-request'));
      const res = yield call(uploadMaterialImage, { locality, mat_id, image });
      if (res.data) {
        if (res.data.status === 'success') {
          yield put(actions.uploadMaterialImageReceive(res.data));
        } else if (res.data.status === 'fail') {
          throw new Error(res.data.message);
        }
      } else if (res.error) {
        throw new Error(res.error.message);
      }
    } catch (error) {
      yield put(actions.uploadMaterialImageFail({
        data: {
          status: 'error',
          message: error.message
        }
      }));
    }
  }

  function* addMaterialSaga({ payload: { materialDetails } }) {
    try {
      yield put(actions.setSnackbarAction('send-request'));
      const res = yield call(addMaterial, { material: materialDetails });
      if (res.data) {
        if (res.data.status === 'success') {
          yield put(actions.addMaterialReceive(res.data));
        } else if (res.data.status === 'fail') {
          throw new Error(res.data.message);
        }
      } else if (res.error) {
        throw new Error(res.error.message);
      }
    } catch (error) {
      yield put(actions.addMaterialFail({
        data: {
          status: 'error',
          message: error.message
        }
      }));
    }
  }

  function* checkCircularMaterialLocalitySaga({ payload: { locality, mat_id } }) {
    try {
      yield put(actions.setSnackbarAction('send-request'));
      const res = yield call(checkCircularMaterialLocality, { locality, mat_id });
      if (res.data) {
        if (res.data.status === 'success') {
          yield put(actions.checkCircularMaterialLocalityReceive(res.data));
        } else if (res.data.status === 'fail') {
          throw new Error(res.data.message);
        }
      } else if (res.error) {
        throw new Error(res.error.message);
      }
    } catch (error) {
      yield put(actions.checkCircularMaterialLocalityFail({
        data: {
          status: 'error',
          message: error.message
        }
      }));
    }
  }

/**
 * Saga watcher function
 */
function* materialSaga() {
  yield takeLatest(actionTypes.GET_ALL_MATERIALS_REQUEST, getAllMaterialsSaga);
  yield takeLatest(actionTypes.GET_ONE_MATERIAL_DETAILS_REQUEST, getMaterialDetailsSaga);
  yield takeLatest(actionTypes.SAVE_ONE_MATERIAL_DETAILS_REQUEST, saveMaterialDetailsSaga)
  yield takeLatest(actionTypes.DELETE_ONE_MATERIAL_REQUEST, deleteMaterialSaga);
  yield takeLatest(actionTypes.UPLOAD_MATERIAL_IMAGE_REQUEST, uploadMaterialImageSaga);
  yield takeLatest(actionTypes.ADD_MATERIAL_REQUEST, addMaterialSaga);
  yield takeLatest(actionTypes.CHECK_CIRCULAR_MATERIAL_LOCALITY_REQUEST, checkCircularMaterialLocalitySaga);
}

export default materialSaga;
