import cts from '../../crud/cts';

/**
 * District API which fetches all municipalities
 */
export const getMunicipality = async () => cts(
  'post',
  'districts/get-all',
);

/**
 * check email API which check duplicate email
 */
export const checkEmailDuplicate = async ({ username, email }) => cts(
  'post',
  'users/check-email-duplicate',
  {
    user_name: username,
    email
  }
);

/**
   * Fetch Details for the given municipalities (like number of households)
   */
export const getMunicipalityDetails = async ({ district_list }) => cts(
  'post',
  'districts/get-district-details',
  {
    district_list,
  }
);

/**
 * Update the municipality with the given number of households
 */
export const setMunicipalityHouseholds = async ({ district_id, household }) => cts(
  'post',
  'districts/update-households',
  {
    district_id,
    household,
  }
);

/**
   * locality API which fetches all locality
   */
export const getLocalities = async () => cts(
  'post',
  'users/locality/get-all'
);

/**
 * Counties API which fetch county name
 */
export const getCounty = async () => cts(
  'get',
  'counties/get-all'
);


export const getDistrictProPackages= async () => {
  return cts(
    'get',
    `districts/get-pro-packages`
  );
};


export const checkCircularMaterialLocality = async ({ locality }) => cts(
  'post',
  'material/check-cm-locality',
  {
    cms_locality_id: locality,
  }
);
