import actionTypes from './actionTypes';

export const setSnackbarAction = (status) => ({
  type: actionTypes.SET_CATEGORY_SNACKBAR_ACTION,
  payload: status
});


export const getAllMaterialsRequest = ({ locality }) => {
  return {
    type: actionTypes.GET_ALL_MATERIALS_REQUEST,
    payload: { locality }
  };
};

export const getAllMaterialsReceive = (data) => ({
  type: actionTypes.GET_ALL_MATERIALS_RECEIVE,
  payload: { data }
});

export const getAllMaterialsFail = (error) => ({
  type: actionTypes.GET_ALL_MATERIALS_FAIL,
  payload: { ...error }
});

export const getOneMaterialDetailsRequest = ({ locality, mat_id }) => ({
  type: actionTypes.GET_ONE_MATERIAL_DETAILS_REQUEST,
  payload: { locality, mat_id }
});

export const getOneMaterialDetailsReceive = (data) => ({
  type: actionTypes.GET_ONE_MATERIAL_DETAILS_RECEIVE,
  payload: { data }
});

export const getOneMaterialDetailsFail = (error) => ({
  type: actionTypes.GET_ONE_MATERIAL_DETAILS_FAIL,
  payload: { ...error }
});

export const clearMaterialDetails = () => ({
  type: actionTypes.CLEAR_MATERIAL_DETAILS
});

export const saveOneMaterialsRequest = (materialDetails) => ({
  type: actionTypes.SAVE_ONE_MATERIAL_DETAILS_REQUEST,
  payload: { materialDetails }
});

export const saveOneMaterialsReceive = (data) => ({
  type: actionTypes.SAVE_ONE_MATERIAL_DETAILS_RECEIVE,
  payload: { data }
});

export const saveOneMaterialsFail = (error) => ({
  type: actionTypes.SAVE_ONE_MATERIAL_DETAILS_FAIL,
  payload: { ...error }
});

export const deleteOneMaterialRequest = ({ locality, mat_id }) => ({
  type: actionTypes.DELETE_ONE_MATERIAL_REQUEST,
  payload: { locality, mat_id }
});

export const deleteOneMaterialReceive = (data) => ({
  type: actionTypes.DELETE_ONE_MATERIAL_RECEIVE,
  payload: { data }
});

export const deleteOneMaterialFail = (error) => ({
  type: actionTypes.DELETE_ONE_MATERIAL_FAIL,
  payload: { ...error }
});

export const uploadMaterialImageRequest = ({ locality, mat_id, image }) => ({
  type: actionTypes.UPLOAD_MATERIAL_IMAGE_REQUEST,
  payload: { locality, mat_id, image }
});

export const uploadMaterialImageReceive = (data) => ({
  type: actionTypes.UPLOAD_MATERIAL_IMAGE_RECEIVE,
  payload: { data }
});

export const uploadMaterialImageFail = (error) => ({
  type: actionTypes.UPLOAD_MATERIAL_IMAGE_FAIL,
  payload: { ...error }
});

export const addMaterialRequest = (materialDetails) => ({
  type: actionTypes.ADD_MATERIAL_REQUEST,
  payload: { materialDetails }
});

export const addMaterialReceive = (data) => ({
  type: actionTypes.ADD_MATERIAL_RECEIVE,
  payload: { data }
});

export const addMaterialFail = (error) => ({
  type: actionTypes.ADD_MATERIAL_FAIL,
  payload: { ...error }
});

export const checkCircularMaterialLocalityRequest = ({ locality, mat_id }) => ({
  type: actionTypes.CHECK_CIRCULAR_MATERIAL_LOCALITY_REQUEST,
  payload: { locality, mat_id }
});

export const checkCircularMaterialLocalityReceive = (data) => {
  return{
  type: actionTypes.CHECK_CIRCULAR_MATERIAL_LOCALITY_RECEIVE,
  payload: { data }
}};

export const checkCircularMaterialLocalityFail = (error) => ({
  type: actionTypes.CHECK_CIRCULAR_MATERIAL_LOCALITY_FAIL,
  payload: { ...error }
});

export const setMaterialSnackbarMessage = (message) => ({
  type: actionTypes.SET_MATERIAL_SNACKBAR_MESSAGE,
  payload: { message }
});
