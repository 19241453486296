import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../redux/createReducer';
import initialState from './store';

const sharedReducer = persistReducer(
  { storage, key: 'root', whitelist: ['municipalities', 'district', 'numberOfLogins', 'fetchState', 'county', 'counties', 'localityList', 'locality', 'isUserPro'] },
  createReducer(initialState, {
    SET_LOADING_ACTION: (state, { payload }) => Immutable({
      ...state,
      fetchState: payload,
    }),

    SET_DISTRICT_ACTION: (state, { payload }) => Immutable({
      ...state,
      district: payload.district,
    }),

    SET_COUNTY_ACTION: (state, { payload }) => Immutable({
      ...state,
      county: payload.county,
    }),

    SET_DATE_RANGE_ACTION: (state, { payload }) => Immutable({
      ...state,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
    }),

    SET_NUMBER_OF_LOGINS: (state, { payload }) => Immutable({
      ...state,
      numberOfLogins: payload.logins,
    }),

    SET_SHOW_TUTORIAL_VIDEO: (state, { payload }) => Immutable({
      ...state,
      showTutorialVideo: payload.value,
    }),

    GET_MUNICIPALITY_LIST_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      municipalities: payload.data.districts,
      fetchState: 'succeed-request',
      isCounty: initialState.isCounty,
      isUserPro: payload.data.is_pro_user !== 0
    }),

    GET_MUNICIPALITY_LIST_FAIL: (state) => Immutable({
      ...state,
      fetchState: 'failed-request'
    }),

    GET_MUNICIPALITY_HOUSEHOLDS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      households: payload.data[0] ? Number(payload.data[0].households) : 0
    }),

    GET_MUNICIPALITY_HOUSEHOLDS_FAIL: (state) => Immutable({
      ...state,
    }),

    SET_MUNICIPALITY_HOUSEHOLDS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      households: payload.household
    }),

    SET_MUNICIPALITY_HOUSEHOLDS_FAIL: (state) => Immutable({
      ...state,
    }),

    GET_LOCALITIES_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'succeed-request',
      localityList: payload.data.data,
      locality: payload.data.data[0],
    }),
    GET_LOCALITIES_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    SET_LOCALITY_ACTION: (state, { payload }) => Immutable({
      ...state,
      locality: payload.locality,
    }),

    GET_COUNTY_LIST_RECEIVE: (state, { payload }) => ({
      ...state,
      counties: payload.data,
      county: payload.data[0],
      // isCounty: true
    }),
    SET_IS_COUNTY_ACTION: (state, { payload }) => ({
      ...state,
      isCounty: payload.isCounty,
    }),
    GET_DISTRICT_PRO_PACKAGES_REQUEST: (state, { payload }) => Immutable({
      ...state,
      loading: true,
      error: null,
    }),
    GET_DISTRICT_PRO_PACKAGES_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      ...payload.data,
      fetchState: 'succeed-request',
    }),
    GET_DISTRICT_PRO_PACKAGES_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      error: payload,
    }),
    CHECK_CIRCULAR_MATERIAL_LOCALITY_REQUEST: (state) => Immutable({
      ...state,
    }),
    CHECK_CIRCULAR_MATERIAL_LOCALITY_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      isCMLocality: payload.data.data.is_circular_material_locality,
    }),
    CHECK_CIRCULAR_MATERIAL_LOCALITY_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),
  })
);

export default sharedReducer;
