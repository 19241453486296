import React from 'react';
import { connect } from 'react-redux';

import MaterialsTable from './MaterialsTable';
import MaterialsDetail from './MaterialsDetail';
import { CmsLoading } from '../../components/loading';

const Content = ({
  fetchState,
  localityFetchState,
  isDetailView,
  setIsDetailView,
}) => {
  const isSendRequest = fetchState === 'send-request' || fetchState === 'send-request' ||localityFetchState === 'send-request';

  return (
    <div className="row">
      <div className="col-12">
        {isSendRequest ? (
          <CmsLoading />
        ) : (
          isDetailView ? <MaterialsDetail setIsDetailView={setIsDetailView} /> : <MaterialsTable setIsDetailView={setIsDetailView} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.materials.fetchState,
  localityFetchState: store.common.fetchState,
});

export default connect(mapStateToProps, null)(Content);
