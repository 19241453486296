import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Portlet } from '../../../../_metronic/partials/content/Portlet';
import { Typography } from '../../components';
import * as actions from '../../redux/actions';
import * as perpetualSchedulesActions from '../../Schedule/PerpetualSchedules/redux/actions';
import { hasCompletedPerpetualSetup } from '../../Schedule/helper';


const PerpetualSchedulesBanner = ({
  district, setDistrictAction, municipalities, getPerpetualSchedulesRequest, perpetualSchedulesDistrict, getSeasonalListRequest, seasonalList
}) => {

  const [displayBanner, setDisplayBanner] = useState(true);

  useEffect(() => {
    if (district?.district_id === '#all-districts#') {
      setDistrictAction(municipalities[0]);
    } else {
      const districts = municipalities.map((municipality) => municipality.district_id);
      getPerpetualSchedulesRequest(districts);

    }
  }, [district]);

  useEffect(() => {
    if (municipalities && municipalities.length && perpetualSchedulesDistrict && perpetualSchedulesDistrict.length && perpetualSchedulesDistrict.length === municipalities.length) {
      const districts = perpetualSchedulesDistrict.map((district) => `${district.project_id}#$#${district.district_id}`).join(',');
      getSeasonalListRequest(districts.split(','));
    }

  }, [perpetualSchedulesDistrict, municipalities]);

  useEffect(() => {
    if (municipalities && municipalities.length && perpetualSchedulesDistrict && perpetualSchedulesDistrict.length && perpetualSchedulesDistrict.length === municipalities.length) {
      if (seasonalList && seasonalList?.seasonal_list.length) {
        const completedSetup = hasCompletedPerpetualSetup(seasonalList);
        if (completedSetup) {
          setDisplayBanner(false);
        } else {
          setDisplayBanner(true);
        }
      } else {
        setDisplayBanner(false);
      }
    }
  }, [seasonalList]);

  return (
    <>
      {
        displayBanner && (
          <Portlet className="p-4">
          <Typography
            style={{ fontSize: '13px' }}
            variant="bodySemibold"
            className="mb-4"
          >
            <strong>In lieu of sending us your 2025 calendar</strong>, you are now able to note your seasonal information and identify which holidays will affect collection using the <strong>Perpetual Schedules</strong> tool. Navigate to the <strong style={{ textDecoration: 'underline' }}> Schedules Tab</strong> where you can access this feature.
          </Typography>
    
          <div className="d-flex align-items-center mt-1">
            <Typography
              style={{ fontSize: '13px' }}
              variant="bodySemibold"
              className="mb-4"
            >
              Set up your schedules today for an easy transition into 2025!
            </Typography>
          </div>
    
        </Portlet>
        )
      }
    </>
  );
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  municipalities: store.common.municipalities,
  perpetualSchedulesDistrict: store.perpetualSchedules.districts,
  seasonalList: store.perpetualSchedules.seasonalList,
});

export default connect(mapStateToProps, { ...actions, ...perpetualSchedulesActions })(PerpetualSchedulesBanner);
