import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { Snackbar } from '../components';
import { Header, Content } from './components';
import {
  Portlet, PortletBody, PortletHeader,
} from '../../../_metronic/partials/content/Portlet';

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    margin: 'auto',
  },
  body: {
    boxShadow: 'none !important',
    width: '85%',
    margin: 'auto',
    [theme.breakpoints.down(900)]: {
      width: '100%'
    },
  },
  porterHeader: {
    display: 'flex',
    width: '100%',
    height: 100,
  },
}));

const Materials = ({
  responseMessage,
  responseStatus,
}) => {
  const classes = useStyles();
  const [isDetailView, setIsDetailView] = useState(false);

  return (
    <div className={classes.content}>
      <Snackbar
        message={responseMessage}
        status={responseStatus}
      />
      <div className="col-xl-12 col-centered">
        <Portlet className={`h-100 position-relative ${classes.body}`}>
          <PortletHeader
            className={`${classes.porterHeader} w-100`}
            title={(
              <div className="d-flex align-items-center justify-content-between w-100">
                <Header title={isDetailView ? "Material Detail" : "Material List"} isDetailView={isDetailView} setIsDetailView={setIsDetailView}/>
              </div>
            )}
          />
          <PortletBody className={`${classes.tableBody}`}>
            <Content isDetailView={isDetailView} setIsDetailView={setIsDetailView} />
          </PortletBody>
        </Portlet>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  responseMessage: store.materials.responseMessage,
  responseStatus: store.materials.responseStatus,
});

export default connect(mapStateToProps, null)(Materials);
