import { combineReducers } from 'redux';

import { metronic } from '../../_metronic';
import authReducer from '../pages/Auth/redux/reducer';
import sharedReducer from '../pages/redux/reducer';
import notificationReducer from '../pages/Notification/redux/reducer';
import reminderReducer from '../pages/Reminder/redux/reducer';
import userReducer from '../pages/Users/redux/reducer';
import campaignReducer from '../pages/Campaign/redux/reducer';
import dashboardReducer from '../pages/Dashboard/redux/reducer';
// import annualSurveyReportReducer from '../pages/Report/AnnualSurveyOLD/redux/reducer';
import annualSurveyReportReducer from '../pages/Report/AnnualSurvey/redux/reducer';
import userReportReducer from '../pages/Report/User/redux/reducer';
import interactionReportReducer from '../pages/Report/Interaction/redux/reducer';
import materialReportReducer from '../pages/Report/Material/redux/reducer';
import newSubscribersReportReducer from '../pages/Report/NewSubscribers/redux/reducer';
import pageViewsReportReducer from '../pages/Report/PageViews/redux/reducer';
import ProblemReportReducer from '../pages/Report/Problem/redux/reducer';
import campaignReportReducer from '../pages/Report/Campaign/redux/reducer';
import categoryReducer from '../pages/Category/redux/reducer';
import depotReducer from '../pages/Depot/redux/reducer';
import eventReducer from '../pages/Event/redux/reducer';
import wasteTypeReducer from '../pages/WasteTypes/redux/reducer';
import collectionRequirementReducer from '../pages/CollectionRequirement/redux/reducer';
import discoveryZoneReducer from '../pages/Report/DiscoveryZone/redux/reducer';
import residentKnowledgeIndicatorReducer from '../pages/Report/ResidentKnowledgeIndicator/redux/reducer';
import badgeReportReducer from '../pages/Report/Badge/redux/reducer';
import wasteAndRecyclingRulesReducer from '../pages/WasteAndRecyclingRules/redux/reducer';
import scheduleReducer from '../pages/Schedule/redux/reducer';
import educationBlogsReducer from '../pages/Education/EducationalContent/Blogs/redux/reducer';
import reportsDashboardReducer from '../pages/Report/ReportDashboard/redux/reducer';
import reportsDashboardNewReducer from '../pages/Report/ReportDashboardNew/redux/reducer';
import WGWHomeReducer from '../pages/WGWHome/redux/reducer';
import WeWantedToKnowReducer from '../pages/Education/EducationalContent/WeWantedToKnow/redux/reducer';
import EducationCommonReducer from '../pages/Education/EducationalContent/redux/reducer';
import WhereDoesThisGoReducer from '../pages/Education/EducationalContent/WhereDoesThisGo/redux/reducer';
import RecyclingForKidsReducer from '../pages/Education/EducationalContent/RecyclingForKids/redux/reducer';
import FeaturedLessonReducer from '../pages/Education/EducationalContent/FeaturedLessons/redux/reducer';
import PDFAddOnsReducer from '../pages/Education/EducationalContent/PdfAddOns/redux/reducer';
import educationBlogsLibraryReducer from '../pages/Education/EducationalContent/BlogLibrary/redux/reducer';
import majorCampaignsReducer from '../pages/Education/EducationalContent/MajorCampaigns/redux/reducer';
import perpetualSchedulesReducer from '../pages/Schedule/PerpetualSchedules/redux/reducer';
import materialsReducer from '../pages/Materials/redux/reducer';



import RecyclingFundamentalsReducer from '../pages/Education/SurveysAndMore/redux/reducer';
import LessonLibraryReducer from '../pages/Education/EducationalContent/LessonLibrary/redux/reducer';

const rootReducer = combineReducers({
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  common: sharedReducer,
  auth: authReducer,
  notification: notificationReducer,
  reminder: reminderReducer,
  user: userReducer,
  campaign: campaignReducer,
  annualSurveyReport: annualSurveyReportReducer,
  userReport: userReportReducer,
  interactionReport: interactionReportReducer,
  materialReport: materialReportReducer,
  newSubscribersReport: newSubscribersReportReducer,
  pageViewsReport: pageViewsReportReducer,
  problemReport: ProblemReportReducer,
  campaignReport: campaignReportReducer,
  dashboard: dashboardReducer,
  category: categoryReducer,
  depot: depotReducer,
  event: eventReducer,
  wasteType: wasteTypeReducer,
  collection: collectionRequirementReducer,
  discoveryZoneReport: discoveryZoneReducer,
  residentKnowledgeIndicatorReport: residentKnowledgeIndicatorReducer,
  badgeReport: badgeReportReducer,
  wasteAndRecyclingRules: wasteAndRecyclingRulesReducer,
  schedule: scheduleReducer,
  educationBlogs: educationBlogsReducer,
  reportsReducer: reportsDashboardReducer,
  reportsDashboardReducer: reportsDashboardNewReducer,
  WGWHome: WGWHomeReducer,
  WWtK: WeWantedToKnowReducer,
  educationCommon: EducationCommonReducer,
  whereDoesThisGo: WhereDoesThisGoReducer,
  RecyclingForKids: RecyclingForKidsReducer,
  featuredLesson: FeaturedLessonReducer,
  pdfAddOns: PDFAddOnsReducer,
  recyclingFundamentals: RecyclingFundamentalsReducer,
  lessonLibrary: LessonLibraryReducer,
  educationBlogsLibrary: educationBlogsLibraryReducer,
  majorCampaigns: majorCampaignsReducer,
  perpetualSchedules: perpetualSchedulesReducer,
  materials: materialsReducer,
});

export default rootReducer;
