import moment from 'moment';

/**
 *
 */
const initialState = {
  fetchState: 'no-request',
  responseStatus: '',
  snackbarState: '',
  responseMessage: '',
  localityList: [],
  locality: {},
  municipalities: [],
  counties: [],
  district: {
    district_id: '#all-districts#',
    district_nm: 'All Districts'
  },
  county: null,
  isCounty: false,
  fromDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
  toDate: moment().format('YYYY-MM-DD'),
  households: 0,
  numberOfLogins: 0,
  showTutorialVideo: false,
  isUserPro: false,
};

export default initialState;
