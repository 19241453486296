import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';

import {
  Typography, AutoComplete
} from '../../components';
import * as actions from '../redux/actions';
import * as sharedActions from '../../redux/actions';
import * as categoryActions from '../../Category/redux/actions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '0 auto'
  },
  topSection: {
    display: 'flex',
    alignContent: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  sectionTitle: {
    color: '#404040',
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 22,
    letterSpacing: '0.05em'
  },
  porterHeader: {
    display: 'flex',
    width: '100%',
    height: 100,
  },
  materialsTable: {
    boxShadow: 'none !important',
    width: '85%',
    margin: 'auto',
    [theme.breakpoints.down(900)]: {
      width: '100%'
    },
  },
  backArrow: {
    fontSize: '2rem',
    color: '#404040',
    '&:hover': {
      color: '#0086C2',
    }
  }
}));

const Header = ({
  intl,
  locality,
  localityList,
  setLocalityAction,
  district,
  setDistrictAction,
  municipalities,
  title,
  getAllMaterialsRequest,
  getWasteTypesListRequest,
  getAllCategoriesRequest,
  clearMaterialDetails,
  setIsDetailView,
  isDetailView,
  checkCircularMaterialLocalityRequest,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (locality.cms_locality_id) {
      // fetch materials
      getAllMaterialsRequest({ locality: locality.cms_locality_id });
      getWasteTypesListRequest({ locality: locality.cms_locality_id });
      getAllCategoriesRequest({ locality: locality.cms_locality_id });
      checkCircularMaterialLocalityRequest({ locality: locality.cms_locality_id });
    }
  }, [locality.cms_locality_id]);

  const goBack = () => {
    clearMaterialDetails();
    setIsDetailView(false);
  };
  const handleLocalityChange = (value) => {
    setLocalityAction({ locality: value });
  };


  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className='d-flex align-items-center'>
        {
          isDetailView &&
          <ArrowBackIcon className={classes.backArrow} onClick={goBack} />
        }
        <Typography variant="h1" className={`${classes.materialsLabel}`}>
          {title}
        </Typography>
      </div>
      {
        !isDetailView &&
        <div style={{ width: 350 }}>
          <AutoComplete
            title={intl.formatMessage({ id: 'GENERAL.MUNICIPALITY' })}
            options={localityList}
            optionTitle="cms_locality_nm"
            optionId="cms_locality_id"
            disableClearable
            onChange={handleLocalityChange}
            value={locality}
          />
        </div>
      }
    </div>
  );
};

const mapStateToProps = (store) => ({
  localityList: store.common.localityList,
  locality: store.common.locality,
  search: store.category.search,
  municipalities: store.common.municipalities,
  district: store.common.district,

});

export default injectIntl(connect(mapStateToProps,
  { ...actions, ...sharedActions, ...categoryActions })(Header));
