// /* eslint-disable import/prefer-default-export */
import cts from '../../crud/cts';

export const getAllMaterials = async ({ locality }) => {
  return cts(
    'post',
    'material/get-all',
    {
      cms_locality_id: locality
    }
  );
};


export const getOneMaterialDetails = async ({ locality, mat_id }) => cts(
  'post',
  'material/get-detail',
  {
    cms_locality_id: locality,
    mat_id: mat_id,
  }
);

export const saveOneMaterialDetails = async ({ material }) => cts(
  'post',
  'material/edit-detail',
  {
    mat_id: material.mat_id,
    mat_cat_id: material.mat_cat_id,
    waste_type_id: material.waste_type_id,
    mat_admin_nm: material.mat_admin_nm,
    mat_nm: material.mat_nm,
    mats_images: material.mats_images,
    mat_synonyms: material.mat_synonyms,
    cms_locality_id: material.cms_locality_id,
    mat_synonyms_has_changed: material.mat_synonyms_has_changed,
  }
);

export const deleteOneMaterial = async ({ locality, mat_id }) => cts(
  'post',
  'material/delete',
  {
    cms_locality_id: locality,
    mat_id: mat_id,
  }
);

export const uploadMaterialImage = async ({ locality, mat_id, image }) => {
  console.log('uploadMaterialImage params:', { locality, mat_id, image: image.get('image') });
  return cts(
    'post',
    'material/upload-assigned-images',
    image, // Pass the FormData directly
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

export const addMaterial = async ({ material }) => cts(
  'post',
  'material/new',
  {
    mat_cat_id: material.mat_cat_id,
    waste_type_id: material.waste_type_id,
    mat_admin_nm: material.mat_admin_nm,
    mat_nm: material.mat_nm,
    mats_images: material.mats_images,
    mat_synonyms: material.mat_synonyms,
    cms_locality_id: material.cms_locality_id,
  }
);

export const checkCircularMaterialLocality = async ({ locality }) => cts(
  'post',
  'material/check-cm-locality',
  {
    cms_locality_id: locality,
  }
);
