import actionTypes from './actionTypes';

// Actions for setting loading status
export const setLoadingAction = (status) => ({
  type: actionTypes.SET_LOADING_ACTION,
  payload: status
});

// Actions for setting district
export const setDistrictAction = (district) => ({
  type: actionTypes.SET_DISTRICT_ACTION,
  payload: { district }
});

// Actions for setting county
export const setCountyAction = (county) => ({
  type: actionTypes.SET_COUNTY_ACTION,
  payload: { county }
});

// Actions for setting isCounty
export const setIsCountyAction = (isCounty) => ({
  type: actionTypes.SET_IS_COUNTY_ACTION,
  payload: { isCounty }
});

// Actions for setting from and to date
export const setDateRangeAction = ({ fromDate, toDate }) => ({
  type: actionTypes.SET_DATE_RANGE_ACTION,
  payload: { fromDate, toDate }
});

// Actions for setting the number of times that user logged in
export const setNumberOfLogins = (logins) => ({
  type: actionTypes.SET_NUMBER_OF_LOGINS,
  payload: { logins }
});

// Actions for setting the flag that indicates whether tutorial pop-up should be displayed
export const setShowTutorialVideo = (value) => ({
  type: actionTypes.SET_SHOW_TUTORIAL_VIDEO,
  payload: { value }
});

// Actions for fetching municipality list
export const getMunicipalityRequest = () => ({
  type: actionTypes.GET_MUNICIPALITY_LIST_REQUEST,
});

export const getMunicipalityReceive = (data) => ({
  type: actionTypes.GET_MUNICIPALITY_LIST_RECEIVE,
  payload: { ...data }
});

export const getMunicipalityFail = (error) => ({
  type: actionTypes.GET_MUNICIPALITY_LIST_FAIL,
  payload: { ...error }
});

// Actions for fetching households of the given district
export const getMunicipalityHouseholdsRequest = ({ districtId }) => ({
  type: actionTypes.GET_MUNICIPALITY_HOUSEHOLDS_REQUEST,
  payload: { district_list: [{ district_id: districtId }] }
});

export const getMunicipalityHouseholdsReceive = (data) => ({
  type: actionTypes.GET_MUNICIPALITY_HOUSEHOLDS_RECEIVE,
  payload: { ...data }
});

export const getMunicipalityHouseholdsFail = (error) => ({
  type: actionTypes.GET_MUNICIPALITY_HOUSEHOLDS_FAIL,
  payload: { ...error }
});

// Actions for updating district households
export const setMunicipalityHouseholdsRequest = ({ districtId, households }) => ({
  type: actionTypes.SET_MUNICIPALITY_HOUSEHOLDS_REQUEST,
  payload: {
    district_id: districtId,
    household: households
  }
});

export const setMunicipalityHouseholdsReceive = (data) => ({
  type: actionTypes.SET_MUNICIPALITY_HOUSEHOLDS_RECEIVE,
  payload: { ...data }
});

export const setMunicipalityHouseholdsFail = (error) => ({
  type: actionTypes.SET_MUNICIPALITY_HOUSEHOLDS_FAIL,
  payload: { ...error }
});

export const getLocalitiesRequest = () => ({
  type: actionTypes.GET_LOCALITIES_REQUEST
});

export const getLocalitiesReceive = (data) => ({
  type: actionTypes.GET_LOCALITIES_RECEIVE,
  payload: { data }
});

export const getLocalitiesFail = (error) => ({
  type: actionTypes.GET_LOCALITIES_FAIL,
  payload: { ...error }
});

export const setLocalityAction = ({ locality }) => ({
  type: actionTypes.SET_LOCALITY_ACTION,
  payload: { locality }
});
// county actions
export const getCountyRequest = () => ({
  type: actionTypes.GET_COUNTY_LIST_REQUEST,
});

export const getCountyReceive = (data) => ({
  type: actionTypes.GET_COUNTY_LIST_RECEIVE,
  payload: { ...data }
});

export const getCountyFail = (error) => ({
  type: actionTypes.GET_COUNTY_LIST_FAIL,
  payload: { ...error }
});

// Get Districts Pro Packages
export const getDistrictProPackagesRequest = (payload) => {
  return {
    type: actionTypes.GET_DISTRICT_PRO_PACKAGES_REQUEST,
    payload
  };
};

// District Pro Packages receive
export const getDistrictProPackagesReceive = (payload) => ({
  type: actionTypes.GET_DISTRICT_PRO_PACKAGES_RECEIVE,
  payload
});

// District Pro Packages fail
export const getDistrictProPackagesFail = (payload) => ({
  type: actionTypes.GET_DISTRICT_PRO_PACKAGES_FAIL,
  payload
});


export const checkCircularMaterialLocalityRequest = ({ locality }) => ({
  type: actionTypes.CHECK_CIRCULAR_MATERIAL_LOCALITY_REQUEST,
  payload: { locality }
});

export const checkCircularMaterialLocalityReceive = (data) => {
  return{
  type: actionTypes.CHECK_CIRCULAR_MATERIAL_LOCALITY_RECEIVE,
  payload: { data }
}};

export const checkCircularMaterialLocalityFail = (error) => ({
  type: actionTypes.CHECK_CIRCULAR_MATERIAL_LOCALITY_FAIL,
  payload: { ...error }
});
